<style lang="less" scoped>
</style>

<template>
  <div>
    <el-form ref="editForm" :model="info" :rules="rules" label-width="100px">
      <el-form-item label="定时时间：" prop="time">
        <el-time-picker
          v-model="info.time"
          @change="timeChange"
          value-format="HH:mm"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
            format: 'HH:mm'
          }"
          placeholder="任意时间点"
        >
        </el-time-picker>
      </el-form-item>

      <el-form-item label="" prop="power">
        <el-radio v-model="info.power" :label="1">开</el-radio>
        <el-radio v-model="info.power" :label="0">关</el-radio>
      </el-form-item>

      <el-form-item>
        <el-button @click="$emit('cancel')">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {setDeviceTime} from '../../../services/device'

export default {
  data() {
    return {
      info: {
        time: null,
        power: 0
      },
      rules: {
        time: [{ required: true, message: "请选择", trigger: "change" }],
        power: [{ required: true, message: "请选择", trigger: "change" }]
      }
    };
  },
  props: {
    propVisible: {
      type: Boolean,
      default: false
    },
    propSels: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    timeChange(val) {
      console.log(val);
    },
    onSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.update()
        }
      });
    },
    async update() {
      const params = Object.assign({}, this.info)
      params.numbers = this.propSels.map(r =>r.mac).join(",")
      const res = await setDeviceTime(params)
      if (res.errorCode === 0) {
        this.$notify.success({
          title: '成功',
          message: '发送命令成功'
        })
        this.$emit('success')
      }
    }
  },
  wathch: {
    propSels(newval) {
      if (!newval) {
        this.info = {
          time: null,
          power: 0
        };
      }
    }
  }
};
</script>