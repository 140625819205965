<style lang="less" scoped>
  .qrcodImg {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .qrcodeDialog {
    .el-dialog--small {
      width: 30%;
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        设备管理
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/device/list' }">
        活跃设备
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getDeviceByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-col>
            <el-form-item>
              <el-select
                v-model="searchType"
                @change="clearSearchType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in [
                    { value: 1, label: '关键字' },
                    { value: 3, label: '微信号' },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-if="searchType === 1"
                clearable
                style="width: 364px"
                v-model="filters.key"
                placeholder="设备名称/mac地址/SN码/设备描述"
              ></el-input>
              <el-input
                v-if="searchType === 2"
                style="width: 364px"
                v-model="filters.mobile"
                placeholder="手机号"
              ></el-input>
              <el-input
                v-if="searchType === 3"
                style="width: 364px"
                v-model="filters.weChat"
                placeholder="微信号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                style="width: 150px"
                v-model="filters.batch"
                type="number"
                placeholder="批次"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                clearable
                style="width: 150px"
                v-model="filters.online"
              >
                <el-option label="在线" :value="1"></el-option>
                <el-option label="离线" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getDeviceByName">
                查询
              </el-button>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item>
              <el-select
                remote
                clearable
                filterable
                v-model="filters.productTypeId"
                placeholder="品牌型号"
              >
                <el-option
                  v-for="item in brandTypeList"
                  :key="item.id"
                  :label="`${item.belongsType}${item.belongsSeries}`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                clearable
                v-model="filters.groupManageId"
                placeholder="群组"
              >
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                clearable
                v-model="filters.provinceId"
                style="width: 121px;"
                placeholder="省"
              >
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>

              <el-select
                clearable
                v-model="filters.cityId"
                style="width: 121px;"
                placeholder="市"
                :disabled="!filters.provinceId"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>

              <el-select
                clearable
                v-model="filters.districtId"
                style="width: 121px;"
                placeholder="区"
                :disabled="!filters.provinceId || !filters.cityId"
              >
                <el-option
                  v-for="item in districtList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col style="margin-bottom: 20px">
            <el-button
              v-if="arrowChangeGroup"
              type="primary"
              :disabled="sels.length === 0"
              @click="showGroup = true"
            >
              分配到群组
            </el-button>
            <el-button
              type="success"
              :disabled="sels.length === 0"
              @click="batchPower(1)"
            >
              批量开
            </el-button>
            <el-button
              type="danger"
              :disabled="sels.length === 0"
              @click="batchPower(0)"
            >
              批量关
            </el-button>
            <!-- <el-button :disabled="sels.length === 0" @click="timingShow = true"
              >批量定时</el-button
            >
            <el-button
              type="warning"
              :disabled="sels.length === 0"
              @click="handleUpgrade"
              >批量升级</el-button
            > -->
          </el-col>
        </el-form>
      </el-col>

      <el-table
        :data="deviceList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>

        <el-table-column
          prop="name"
          min-width="140"
          :show-overflow-tooltip="true"
          label="名称"
        >
          <template slot-scope="scope">
            <span
              style="cursor:pointer;"
              @click="gotoPath(scope.row, 'refUsers')"
            >
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="mac"
          :show-overflow-tooltip="true"
          min-width="135"
          label="MAC"
        >
          <template slot-scope="scope">
            <span
              style="cursor:pointer;"
              @click="gotoPath(scope.row, 'refData')"
            >
              {{ scope.row.mac }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="productTypeCode"
          min-width="140"
          :show-overflow-tooltip="true"
          label="品牌型号"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.belongsType + scope.row.belongsSeries }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="sn"
          min-width="100"
          :show-overflow-tooltip="true"
          label="SN"
        ></el-table-column>

        <el-table-column
          prop="groupManageName"
          min-width="140"
          :show-overflow-tooltip="true"
          label="所属群组"
        ></el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="联网状态"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.isOnline ? "在线" : "离线" }}
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="定时"
          align="center"
        >
          <template slot-scope="scope">
            <span></span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="PM2.5"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.pm2_5 }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="CO2"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.co2 }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="TVCO"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.tvoc }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="温度值"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.temp }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="湿度值"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.humidity }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          width="90"
          :show-overflow-tooltip="true"
          label="HCHO"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.deviceSensor && scope.row.deviceSensor.hcho }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="batch"
          width="100"
          :show-overflow-tooltip="true"
          label="批次"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.batch ? scope.row.batch : "暂无" }}
          </template>
        </el-table-column>

        <el-table-column
          prop="updateTimePm"
          width="160"
          :show-overflow-tooltip="true"
          label="数据更新时间"
          align="center"
        >
          <template slot-scope="scope">
            {{ formatTime(scope.row.updateTimePm) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="remarks"
          width="160"
          :show-overflow-tooltip="true"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" @click="unbindDvc(scope.row.mac)">
              解绑
            </el-button>
            <router-link
              :to="{
                path: '/device/info/' + scope.row.mac,
                query: { searchKey: filters.key ? filters.key : '' },
              }"
            >
              <el-button type="primary" size="small" class="m-l-xs">
                详情
              </el-button>
            </router-link>

            <el-button
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <router-link to="/device/detail">
          <!-- <el-button :disabled='!editPermission' type="primary">新增设备</el-button> -->
        </router-link>
        <el-button
          type="danger"
          :disabled="!this.sels.length > 0"
          @click="batchRemove"
        >
          批量删除
        </el-button>
        <!-- <el-button type="primary" v-if="isAdmin" @click="goAuthorize">授&nbsp;&nbsp;权</el-button>
		        <el-button type="primary" v-if='isAdmin' @click="batchAuthShow=true">批量授权</el-button>
		        <el-button v-if="isAdmin" type="primary">初始化</el-button> -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog title="分配群组" class="qrcodeDialog" :visible.sync="showGroup">
      <el-form ref="editForm" :model="groupInfo" :rules="groupRules">
        <el-form-item label="所属群组：" prop="groupManageId">
          <el-select
            style="width: 100%;"
            v-model="groupInfo.groupManageId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="showGroup = false">取消</el-button>
        <el-button type="primary" @click="handleUpdateGroup">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量定时" :visible.sync="timingShow">
      <Timing
        :propVisible="timingShow"
        :propSels="sels"
        @success="timingShow = false"
        @cancel="timingShow = false"
      ></Timing>
    </el-dialog>

    <el-dialog title="批量升级" :visible.sync="upgradeShow">
      <Upgrade
        :propVisible="upgradeShow"
        :propSels="sels"
        @success="upgradeShow = false"
        @cancel="upgradeShow = false"
      ></Upgrade>
    </el-dialog>
  </div>
</template>

<script>
  import {
    searchDevicesByKey,
    searchDevicesByCall,
    removeDevice,
    enabledDevice,
    createDevice,
    searchProByCustomer,
    unbindDvcByMac,
    batchSendPower,
    updateDeviceGroup,
    updateDeviceSecondGroup,
  } from "../../services/device";
  import { getAssignGroups } from "../../services/group.js";
  import { API_ROOT } from "../../config";
  import { updateDefaultDevice } from "../../services/ucenter";
  import {
    getAllCustomers,
    searchCustomerByKey,
  } from "../../services/customer";
  import {
    getProvinces,
    getCitesByProvince,
    getDistrictsByCity,
  } from "../../services/common";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import Timing from "./components/batch-timing";
  import Upgrade from "./components/batch-upgrade";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  let tempKey = null;

  export default {
    beforeRouteEnter(to, from, next) {
      if (from.query.searchKey) {
        tempKey = from.query.searchKey;
      } else {
        tempKey = null;
      }
      next();
    },
    components: {
      Timing,
      Upgrade,
    },
    data() {
      return {
        API_ROOT,
        showGroup: false,
        timingShow: false,
        upgradeShow: false,
        searchType: 1,

        isAdmin: false,
        isProxy: false,
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
          //sort: 'updateTime,desc'
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        groupList: [],
        deviceList: [],
        groupInfo: {
          ids: "",
          groupManageId: "",
        },
        groupRules: {
          groupManageId: [
            {
              required: true,
              message: "请选择群组",
              trigger: "change",
            },
          ],
        },
        filters: {
          key: "",
          customer: null,
          mobile: null,
          weChat: null,
          batch: null,
          productTypeId: "",
          publicNumberProductTypeId: "",
          groupManageId: "",
          provinceId: "",
          cityId: "",
          districtId: "",
          status: 1,
        },
        deviceInfo: {
          customer: "",
          deviceKey: "",
          publicNumberproductTypeId: "",
          macAddr: "",
          sn: "",
          remarks: "",
        },
        listLoading: false,
        sels: [], //列表选中列
        authoShow: false,
        groupList: [],
        brandList: [], //品牌商列表
        provinceList: [],
        cityList: [],
        districtList: [],
        brandTypeList: [], //
        batchAuthShow: false,
        // rules: {
        //   name: [
        //     {validator: valiName, trigger: 'blur'}
        //   ],
        //   code:[
        //     {validator: valiRuleCode, trigger: 'blur'}
        //   ]
        // },
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
      ...mapGetters({ roles: "roles" }),
      arrowChangeGroup() {
        console.warn(this.user);
        if (
          this.user.role === "admin" ||
          (this.user.role === "bigScrean" && this.user.groupManageLevel === 1)
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      clearSearchType() {
        this.filters.key = "";
        this.filters.mobile = null;
        this.filters.weChat = null;
      },

      gotoPath(row, toElement) {
        window.router.push({
          path: "/device/info/" + row.mac,
          query: {
            toElement: toElement,
          },
        });
      },

      handleUpgrade() {
        const flag = this.sels.some(
          (r) =>
            r.publicNumberProductTypeId !==
            this.sels[0].publicNumberProductTypeId
        );
        if (flag) {
          this.$notify.warning({
            title: "警告",
            message: "所选设备品牌型号不一致",
          });
        } else {
          this.upgradeShow = true;
        }
      },

      handleUpdateGroup() {
        this.$refs.editForm.validate(async (valid) => {
          if (valid) {
            let resData = null;
            this.groupInfo.ids = this.sels.map((item) => item.id).toString();

            if (
              this.user.role === "bigScrean" &&
              this.user.groupManageLevel === 1
            ) {
              resData = await updateDeviceSecondGroup(this.groupInfo);
            }

            if (this.user.role === "admin") {
              resData = await updateDeviceGroup(this.groupInfo);
            }
            if (resData.errorCode === 0 && resData.data) {
              this.$notify.success({
                title: "成功",
                message: "分配成功",
              });
              this.showGroup = false;
            }
          }
        });
      },

      batchPower(power) {
        const numbers = this.sels.map((item) => item.mac).toString();
        this.$confirm(`将${power == 1 ? "开启" : "关闭"}选中设备`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            batchSendPower(numbers, power).then((res) => {
              if (res.errorCode === 0) {
                this.$notify.success({
                  title: "成功",
                  message: "命令发送成功,请稍后刷新查看",
                });
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 设备编辑权限判断
       */
      submitDvc() {
        this.dvcAuthorize();
      },

      // goAuthorize() {
      // 	this.authoShow = true
      // 	this.queryCustomer()
      // },

      /**
       * 判断登陆身份
       */
      async checkRole() {
        const role = this.user.code;
        if (role.test("admin")) {
          this.isAdmin = true;
        } else if (role.test("brands")) {
          this.isProxy = true;
          this.filters.customer = this.user.customerId;
        }
      },
      /**
       * 创建设备授权
       */
      async dvcAuthorize() {
        const dvc = this.deviceInfo;
        console.log(dvc);
        const res = await createDevice(dvc);
        console.log(res);
      },
      /**
       * 解绑授权设备
       */
      async unbindDvc(mac) {
        this.$confirm("确认解绑该设备？", "提示", { type: "warning" }).then(
          () => {
            unbindDvcByMac(mac).then((res) => {
              if (res && res.errorCode === 0 && res.data === "done") {
                this.queryDevices();
                Notification.success({
                  title: "成功",
                  message: "解绑成功",
                });
              } else {
                Notification.error({
                  tittle: "错误",
                  message: "解绑失败",
                });
              }
            });
          }
        );
      },
      /**
       * 获取所有品牌商
       */
      // async queryCustomer(key) {
      // 	const responseData = await searchCustomerByKey({ key, isProxy: true }, this.pager)
      // 	if (responseData.errorCode === 0) {
      // 		this.brandList = (responseData && responseData.data && responseData.data.datas) || []
      // 		this.queryBrandTypeList(this.deviceInfo.customer)
      // 	}
      // 	this.loaded = true
      // 	this.listLoading = false
      // },

      /**
       * 获取品牌型号列表
       */
      async queryBrandTypeList() {
        const responseData = await searchProByCustomer(null, {
          page: 1,
          size: 9999,
        });
        if (responseData.errorCode === 0 && responseData.data) {
          this.brandTypeList = responseData.data.list || [];
        }
      },

      // 获取所有群组
      async queryGroups(key) {
        const resData = await getGroupList(key);
        if (resData.errorCode === 0 && resData.data) {
          this.groupList = resData.data.datas || [];
        }
      },

      // 获取所有省份
      async queryProvinces() {
        const resData = await getProvinces();
        if (resData.errorCode === 0 && resData.data) {
          this.provinceList = resData.data || [];
        }
      },

      // 根据省份获取城市
      async queryCites(id) {
        const resData = await getCitesByProvince(id);
        if (resData.errorCode === 0 && resData.data) {
          this.cityList = resData.data || [];
        }
      },

      async queryDistricts(id) {
        const resData = await getDistrictsByCity(id);
        if (resData.errorCode === 0 && resData.data) {
          this.districtList = resData.data || [];
        }
      },

      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        this.editPermission = permissions.some((item) => {
          return item === "Device:update";
        });
      },
      formatTime(time) {
        return time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : "暂无";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeDevice(row.mac).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.deviceDetailVisible = true;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedDevice = Object.assign({}, row);
        this.deviceInfo = {
          id: choosedDevice.id,
          name: choosedDevice.name,
          contact: choosedDevice.contact,
          contactTel: choosedDevice.contactTel,
          addrProvince:
            choosedDevice.addrProvince && choosedDevice.addrProvince.id,
          isProxy: choosedDevice.isProxy,
          address: choosedDevice.address,
          remarks: choosedDevice.remarks,
        };
      },
      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeDevice(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryDevices();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据设备名筛选
       */
      getDeviceByName() {
        this.queryDevices();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryDevices();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryDevices();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryDevices();
      },

      // 获取所有群组
      async queryGroups() {
        const resData = await getAssignGroups(
          { key: "" },
          { page: 1, size: 9999 }
        );
        if (resData.errorCode === 0 && resData.data) {
          this.groupList = resData.data.datas || [];
        }
      },

      /**
       * 获取所有的设备
       */
      async queryDevices() {
        this.listLoading = true;
        for (let searchvalue in this.filters) {
          if (this.filters.searchvalue === "") {
            this.filters.searchvalue = null;
          }
        }
        let searchParam = Object.assign({}, this.filters);
        if (this.filters.weChat || this.filters.mobile) {
          this.filters.key = null;
          const responseData = await searchDevicesByCall(
            this.filters,
            this.pager
          );
          if (responseData.errorCode === 0 && responseData.data) {
            this.deviceList = responseData.data.list || [];
            this.total = responseData.data.total;
          }
        } else {
          const res = await searchDevicesByKey(searchParam, this.pager);
          // console.log('res111111111111',res)
          if (res.errorCode === 0 && res.data) {
            this.deviceList = res.data.list || [];
            this.total = res.data.total;
          }
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      this.filters.key = tempKey;
      const role = this.user.role;
      if (/admin/.test(role)) {
        this.isAdmin = true;
      } else if (/brands/.test(role)) {
        this.isProxy = true;
        this.filters.customer = this.user.customerId;
      }
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryGroups();
      this.queryProvinces();
      // this.queryCustomer();
      this.queryDevices();
      this.queryBrandTypeList();
    },
    watch: {
      "filters.provinceId"(newVal) {
        this.filters.cityId = "";
        this.cityList = [];
        this.filters.districtId = "";
        this.districtList = [];
        if (newVal) {
          this.queryCites(newVal);
        }
      },
      "filters.cityId"(newVal) {
        this.filters.districtId = [];
        this.districtList = [];
        if (newVal) {
          this.queryDistricts(newVal);
        }
      },
    },
  };
</script>
