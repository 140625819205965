<style lang="less" scoped>
</style>

<template>
  <div>
    <el-form ref="editForm" :model="info" :rules="rules" label-width="100px">
      <el-form-item label="版本号：">
        <el-select
          v-model="info.version"
          placeholder="请选择版本号"
          style="width:100%;"
        >
          <el-option
            v-for="item in versionList"
            :key="item.id"
            :label="item.swversion"
            :value="item.swversion"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="$emit('cancel')">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { batchUpgrade, getVersionsByDvcType } from "../../../services/setting";

export default {
  data() {
    return {
      versionList: [],
      info: {
        version: '',
      },
      rules: {
        version: [{ required: true, message: "请选择", trigger: "change" }],
      }
    };
  },
  props: {
    propVisible: {
      type: Boolean,
      default: false
    },
    propSels: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    timeChange(val) {
      console.log(val);
    },
    onSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.update();
        }
      });
    },
    async update() {
      const params = Object.assign({}, this.info);
      params.numbers = this.propSels.map(r => r.mac).join(",");
      const res = await batchUpgrade(params);
      if (res.errorCode === 0) {
        this.$notify.success({
          title: "成功",
          message: "发送命令成功"
        });
        this.$emit("success");
      }
    },

    //获取版本号
    async getVersionList() {
      const dvcInfo = this.propSels[0]
      const params = {
        deviceType: dvcInfo.deviceTypeCode + dvcInfo.productTypeCode,
        publicNumberProductTypeId: dvcInfo.publicNumberProductTypeId
      };
      const res = await getVersionsByDvcType(params);
      if (res && res.errorCode === 0 && res.data) {
        this.versionList = res.data || [];
      }
    },
  },
  created() {
    this.getVersionList()
  },
  wathch: {
    propSels(newval) {
      if (newval) {
        this.getVersionList()
      } else {
        this.info = {
          version: '',
        };
      }
    }
  }
};
</script>